import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's styles

const QuillEditor = ({ initialContent, onChange }) => {

  const handleModelChange = (newContent) => {
    onChange(newContent);
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }],
      [{ 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link'],
      [{ 'color': [] }, { 'background': [] }],
    ],
  };

  return (
    <ReactQuill
      value={initialContent}
      onChange={handleModelChange}
      modules={modules}
      theme="snow" 
    />
  );
};

export default QuillEditor;
